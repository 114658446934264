// Determine bg height
$var-section--padding__mobile: $section--padding__mobile;
$var-section--padding__desktop: $var-section--padding__mobile;
@if ($section--padding__desktop != null) {
	$var-section--padding__desktop: $section--padding__desktop;
}
@if ($testimonial--padding-b__mobile != null) {
	$var-section--padding__mobile: $testimonial--padding-b__mobile;
	$var-section--padding__desktop: $testimonial--padding-b__mobile;
}
@if ($testimonial--padding-b__desktop != null) {
	$var-section--padding__desktop: $testimonial--padding-b__desktop;
}
$var-tiles-items--padding__mobile: $tiles-items--padding__mobile;
$var-tiles-items--padding__desktop: $var-tiles-items--padding__mobile;
@if ($tiles-items--padding__desktop != null) {
	$var-tiles-items--padding__desktop: $tiles-items--padding__desktop;
}
@if ($testimonial-items--padding__mobile != null) {
	$var-tiles-items--padding__mobile: $testimonial-items--padding__mobile;
	$var-tiles-items--padding__desktop: $testimonial-items--padding__mobile;
}
@if ($testimonial-items--padding__desktop != null) {
	$var-tiles-items--padding__desktop: $testimonial-items--padding__desktop;
}

$testimonial-bg--height_mobile: $var-section--padding__mobile - $var-tiles-items--padding__mobile / 2;
$testimonial-bg--height_desktop: $var-section--padding__desktop - $var-tiles-items--padding__desktop / 2;

.testimonial {

	.has-bg-color {
		position: relative;
		background-color: transparent;
		z-index: auto;

		&::before {
			content: '';
			position: absolute;
			bottom: -$testimonial-bg--height_mobile;
			left: 50%;
			width: 300%;
			height: calc(100% - 92px + #{$testimonial-bg--height_mobile});
			transform: translateX(-50%);
			background: get-color(dark, 1);
			z-index: -3;
		}
	}

	.tiles-item-inner {
		position: relative;
		background: get-color(light, 1);
	}

	&.invert-color,
	.invert-color {

		.tiles-item-inner {
			background: get-color(dark, 2);
		}
	}
}

.testimonial-item-image {
	position: relative;
	margin-top: 16px;

	img {
		border-radius: 50%;
		box-shadow: 0 12px 24px rgba(get-color(dark, 1), .32);
	}

	&::before {
		content: '';
		position: absolute;
		top: -8px;
		left: -2px;
		width: 25px;
		height: 18px;
		background-image: inline-svg('<svg width="25" height="18" xmlns="http://www.w3.org/2000/svg"><path d="M.438 13.481c0-2.34.611-4.761 1.833-7.263S5.171 1.643 7.303 0l4.135 2.689C10.164 4.382 9.214 6.093 8.59 7.824c-.624 1.73-.936 3.578-.936 5.545V18H.438v-4.519zm13 0c0-2.34.611-4.761 1.833-7.263s2.9-4.575 5.032-6.218l4.135 2.689c-1.274 1.693-2.224 3.404-2.848 5.135-.624 1.73-.936 3.578-.936 5.545V18h-7.216v-4.519z" fill="' + get-color(primary, 2) + '" fill-rule="nonzero"/></svg>');
		background-repeat: no-repeat;
	}
}

.testimonial-item-name {
	color: get-color(dark, 1);
}

.invert-color {

	.testimonial-item-name {
		color: get-color(light, 3);
	}

	.testimonial-item-link {

		a:not(.button) {
			color: get-color(primary, 2);
		}
	}
}

@include media( '>medium' ) {

	.testimonial {

		.has-bg-color {

			&::before {
				bottom: -$testimonial-bg--height_desktop;
				height: calc(100% - 285px + #{$testimonial-bg--height_desktop});
			}
		}
	}
}

@include media( '>1145px' ) { // 1145px may need to e adjusted if testimonial items width or outer padding change
	.testimonial {

		.tiles-wrap {
			padding-bottom: 64px;
		}

		.tiles-item {
			position: relative;

			&:nth-child(3n+1) {
				top: 32px;
			}

			&:nth-child(3n+2) {
				top: 64px;
			}
		}
	}
}
