// Login / Signup forms (core/sections/_contact.scss)
$contact--padding-t__mobile: 		null;		// contact, top padding (mobile)
$contact--padding-b__mobile: 		null;		// contact, bottom padding (mobile)
$contact--padding-t__desktop: 		null;		// contact, top padding (desktop)
$contact--padding-b__desktop: 		null;		// contact, bottom padding (desktop)
$contact-header--padding__mobile: 	32px;		// contact header, bottom padding (mobile)
$contact-header--padding__desktop:	48px;		// contact header, bottom padding (desktop)
$contact-item--width: 				540px;		// contact item, width
$contact-item--inner-padding-v: 		0;			// contact item, inner top and bottom padding (it inherits $tiles-item--inner-padding-v unless you replace null with a new value)
$contact-item--inner-padding-h: 		0;			// contact item, inner left and right padding (it inherits $tiles-item--inner-padding-h unless you replace null with a new value)
