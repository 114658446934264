.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {

	// Affects only hero full
	> .hero-content,
	> .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure, .hero-figure-restaurant {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}

	.hero-figure-wrapper {
		position: relative;
		height: 800px;
		margin-top: -250px;
	} 

	.hero-figure {
		max-width: 1440px;
		margin: 0 auto;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		// max-width: $container--width + ( $container--padding__desktop * 2 );
		// margin: -85px auto 0 auto;
	}

	> .hero-figure-restaurant {
		max-width: 1440px;
		margin: 0 auto;
	}
}

@include media( '<=1024px' ) {
	.hero {
		.hero-figure-wrapper {
			margin-top: -125px;
			height: 500px;
		} 
		.hero-figure {
			max-width: 800px;
		}
	}
}


@include media( '<=medium' ) {

	.hero {
		.hero-figure {
			margin: 0 auto;	
		}

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content,
		> .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}
