.cta-inner {
	padding-left: 16px;
	padding-right: 16px;
	background-color: get-color(primary, 1);
	background-image: url(../../../images/cta-illustration.svg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: right top;
}

.second-color {
	background-color: get-color(secondary, 1);
} 

@include media( '>medium' ) {

	.cta-inner {
		padding-left: 48px;
		padding-right: 48px;
	}
}
