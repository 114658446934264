.button {
	box-shadow: 0 8px 20px rgba(get-color(dark, 1), .1);

	&:not(.button-dark):not(.button-primary):not(.button-secondary) {
		background: linear-gradient(65deg, get-color(light, 1) 0, rgba(get-color(light, 1), .8) 100%);
		background-origin: border-box;

		&:hover {
			background-color: color-bg(button-light-hover);
		}
	}
}
